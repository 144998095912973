// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'jquery';
import 'popper.js';
import 'bootstrap';
import "dropzone";

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "@fortawesome/fontawesome-free/css/all";

Rails.start();
ActiveStorage.start();

import "stylesheets/application";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { Turbo } from "@hotwired/turbo-rails";

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

$( document ).on('turbo:load', function() {
  console.log("LOAD")

  $(".clickable-row").on("click", function (event) {
    console.log("CLICK", event)
    var href = $(event.currentTarget).find(".click-this").attr("href")
    console.log(href)
  });
})
