// src/controllers/hello_controller.js
import { Controller } from "stimulus";

export default class extends Controller {

  
  connect() {
    console.log("AutoTextController::connect()", this.element);
    
    console.log(this.data.get("keys"));
    console.log(this.data);

    this.keys = this.data.get("keys").split(",")
    
    // this.element.addEventListener("keyup", event => {
    //   this.checkAutoText(event)
    // });

  }

  checkAutoText(event) {

    console.log("AT:", event)

    var cursorPosition = $(this.element).prop("selectionStart");

    var text = $(this.element).val();
    var textToPos = text.substr(0, cursorPosition);
    console.log("textToPos", textToPos);

    const regex = /\s/gm;
    var start = 0;
    var match;
    while ((match = regex.exec(textToPos)) != null) {
      start = match.index +1 
    }

    var word = textToPos.substr(start);
    console.log("this.keys " , this.keys);
    console.log(this.keys.includes(":eins"))
    console.log("word ["+word+"]");

    if (this.keys.includes(word)) {
      console.log("AUTPUT TEXT MF")
      this.insertAutoText(start, cursorPosition, "Si la válvula cardíaca ha sido destruida en gran parte por endocarditis, debe ser reemplazada por una artificial.")
    }

  }

  insertAutoText(p1, p2, macro) {
    var text = $(this.element).val();
    var newText = text.substr(0, p1) + macro + text.substr(p2, text.length);
    $(this.element).val(newText);
  }


}
