// src/controllers/hello_controller.js
import { Controller } from "stimulus";
import { debounce, debounceTime, map } from 'rxjs/operators';
import { interval, Subject } from "rxjs";

export default class extends Controller {

  static targets = [ "search", "form" ]

  connect() {
    console.log("PatientsController::connect()", this.element);

    this.input = new Subject();
    this.input.pipe(debounce( () => interval(300))).subscribe(this.searchPatient);

    this.searchTarget.addEventListener("keyup", event => {
      this.onInputChange(event)
    });
  }

  searchPatient = x => {
    console.log("Search:", this.searchTarget.value)
    this.formTarget.requestSubmit();
  }

  onInputChange(event) {
    this.input.next();
  }
}
