// src/controllers/hello_controller.js
import { Controller } from "stimulus";

export default class extends Controller {

  
  connect() {
    console.log("RegisterController::connect()");
  }

  clickConfirm() {
    $(this.element).removeClass("step1").addClass("step2")
  }

}
